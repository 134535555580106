/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataDictionaryEntry,
  DataSet,
  DataSetExportRequest,
  DataSetListResponse,
  DataSetPreviewRequest,
  DataSetPreviewResponse,
  DataSetRequest,
  DomainValuesResponse,
  EmptyResponse,
  ErrorResponse,
  GenomicExtractionJob,
  GenomicExtractionJobListResponse,
  MarkDataSetRequest,
  ReadOnlyNotebookResponse,
  ResourceTypeRequest,
  TanagraGenomicDataRequest,
} from '../models';
import {
    DataDictionaryEntryFromJSON,
    DataDictionaryEntryToJSON,
    DataSetFromJSON,
    DataSetToJSON,
    DataSetExportRequestFromJSON,
    DataSetExportRequestToJSON,
    DataSetListResponseFromJSON,
    DataSetListResponseToJSON,
    DataSetPreviewRequestFromJSON,
    DataSetPreviewRequestToJSON,
    DataSetPreviewResponseFromJSON,
    DataSetPreviewResponseToJSON,
    DataSetRequestFromJSON,
    DataSetRequestToJSON,
    DomainValuesResponseFromJSON,
    DomainValuesResponseToJSON,
    EmptyResponseFromJSON,
    EmptyResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GenomicExtractionJobFromJSON,
    GenomicExtractionJobToJSON,
    GenomicExtractionJobListResponseFromJSON,
    GenomicExtractionJobListResponseToJSON,
    MarkDataSetRequestFromJSON,
    MarkDataSetRequestToJSON,
    ReadOnlyNotebookResponseFromJSON,
    ReadOnlyNotebookResponseToJSON,
    ResourceTypeRequestFromJSON,
    ResourceTypeRequestToJSON,
    TanagraGenomicDataRequestFromJSON,
    TanagraGenomicDataRequestToJSON,
} from '../models';

export interface DataSetApiAbortGenomicExtractionJobRequest {
    workspaceNamespace: string;
    terraName: string;
    jobId: string;
}

export interface DataSetApiCreateDataSetRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetRequest: DataSetRequest;
}

export interface DataSetApiDeleteDataSetRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetId: number;
}

export interface DataSetApiExportToNotebookRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetExportRequest: DataSetExportRequest;
}

export interface DataSetApiExtractGenomicDataRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetId: number;
}

export interface DataSetApiExtractTanagraGenomicDataRequest {
    workspaceNamespace: string;
    terraName: string;
    tanagraGenomicDataRequest: TanagraGenomicDataRequest;
}

export interface DataSetApiGetDataDictionaryEntryRequest {
    cdrVersionId: number;
    domain: string;
    value: string;
}

export interface DataSetApiGetDataSetRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetId: number;
}

export interface DataSetApiGetDataSetByResourceIdRequest {
    workspaceNamespace: string;
    terraName: string;
    id: number;
    resourceTypeRequest: ResourceTypeRequest;
}

export interface DataSetApiGetGenomicExtractionJobsRequest {
    workspaceNamespace: string;
    terraName: string;
}

export interface DataSetApiGetValuesFromDomainRequest {
    workspaceNamespace: string;
    terraName: string;
    domain: string;
    conceptSetId?: number;
}

export interface DataSetApiMarkDirtyRequest {
    workspaceNamespace: string;
    terraName: string;
    markDataSetRequest?: MarkDataSetRequest;
}

export interface DataSetApiPreviewDataSetByDomainRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetPreviewRequest: DataSetPreviewRequest;
}

export interface DataSetApiPreviewExportToNotebookRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetExportRequest: DataSetExportRequest;
}

export interface DataSetApiUpdateDataSetRequest {
    workspaceNamespace: string;
    terraName: string;
    dataSetId: number;
    dataSet?: DataSetRequest;
}

/**
 * 
 */
export class DataSetApi extends runtime.BaseAPI {

    /**
     * Aborts a running genome extract job. Requires WRITER access on the workspace. Workspace CDR must also have access to a Wgs dataset
     */
    async abortGenomicExtractionJobRaw(requestParameters: DataSetApiAbortGenomicExtractionJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling abortGenomicExtractionJob.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling abortGenomicExtractionJob.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling abortGenomicExtractionJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/genomicExtractionJobs/{jobId}/abort`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Aborts a running genome extract job. Requires WRITER access on the workspace. Workspace CDR must also have access to a Wgs dataset
     */
    async abortGenomicExtractionJob(workspaceNamespace: string, terraName: string, jobId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.abortGenomicExtractionJobRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, jobId: jobId }, initOverrides);
        return await response.value();
    }

    /**
     * Creates Data Set
     */
    async createDataSetRaw(requestParameters: DataSetApiCreateDataSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling createDataSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling createDataSet.');
        }

        if (requestParameters.dataSetRequest === null || requestParameters.dataSetRequest === undefined) {
            throw new runtime.RequiredError('dataSetRequest','Required parameter requestParameters.dataSetRequest was null or undefined when calling createDataSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/data-sets`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSetRequestToJSON(requestParameters.dataSetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetFromJSON(jsonValue));
    }

    /**
     * Creates Data Set
     */
    async createDataSet(workspaceNamespace: string, terraName: string, dataSetRequest: DataSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSet> {
        const response = await this.createDataSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetRequest: dataSetRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the data set with the specified ID
     */
    async deleteDataSetRaw(requestParameters: DataSetApiDeleteDataSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling deleteDataSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling deleteDataSet.');
        }

        if (requestParameters.dataSetId === null || requestParameters.dataSetId === undefined) {
            throw new runtime.RequiredError('dataSetId','Required parameter requestParameters.dataSetId was null or undefined when calling deleteDataSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{workspaceNamespace}/{terraName}/data-sets/{dataSetId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"dataSetId"}}`, encodeURIComponent(String(requestParameters.dataSetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the data set with the specified ID
     */
    async deleteDataSet(workspaceNamespace: string, terraName: string, dataSetId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.deleteDataSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetId: dataSetId }, initOverrides);
        return await response.value();
    }

    /**
     * Exports a data set query to a notebook
     */
    async exportToNotebookRaw(requestParameters: DataSetApiExportToNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling exportToNotebook.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling exportToNotebook.');
        }

        if (requestParameters.dataSetExportRequest === null || requestParameters.dataSetExportRequest === undefined) {
            throw new runtime.RequiredError('dataSetExportRequest','Required parameter requestParameters.dataSetExportRequest was null or undefined when calling exportToNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{workspaceNamespace}/{terraName}/data-set/exportToNotebook`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSetExportRequestToJSON(requestParameters.dataSetExportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyResponseFromJSON(jsonValue));
    }

    /**
     * Exports a data set query to a notebook
     */
    async exportToNotebook(workspaceNamespace: string, terraName: string, dataSetExportRequest: DataSetExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyResponse> {
        const response = await this.exportToNotebookRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetExportRequest: dataSetExportRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Initiates a Terra Cromwell job to extract genomes of the given dataset. Requires WRITER access on the workspace. Workspace CDR must also have an associated WGS dataset
     */
    async extractGenomicDataRaw(requestParameters: DataSetApiExtractGenomicDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenomicExtractionJob>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling extractGenomicData.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling extractGenomicData.');
        }

        if (requestParameters.dataSetId === null || requestParameters.dataSetId === undefined) {
            throw new runtime.RequiredError('dataSetId','Required parameter requestParameters.dataSetId was null or undefined when calling extractGenomicData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/datasets/{dataSetId}/extractGenomicData`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"dataSetId"}}`, encodeURIComponent(String(requestParameters.dataSetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenomicExtractionJobFromJSON(jsonValue));
    }

    /**
     * Initiates a Terra Cromwell job to extract genomes of the given dataset. Requires WRITER access on the workspace. Workspace CDR must also have an associated WGS dataset
     */
    async extractGenomicData(workspaceNamespace: string, terraName: string, dataSetId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenomicExtractionJob> {
        const response = await this.extractGenomicDataRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetId: dataSetId }, initOverrides);
        return await response.value();
    }

    /**
     * Initiates a Terra Cromwell job to extract genomes of the given Tanagra-enabled dataset. Requires WRITER access on the workspace. Workspace CDR must also have an associated WGS dataset
     */
    async extractTanagraGenomicDataRaw(requestParameters: DataSetApiExtractTanagraGenomicDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenomicExtractionJob>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling extractTanagraGenomicData.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling extractTanagraGenomicData.');
        }

        if (requestParameters.tanagraGenomicDataRequest === null || requestParameters.tanagraGenomicDataRequest === undefined) {
            throw new runtime.RequiredError('tanagraGenomicDataRequest','Required parameter requestParameters.tanagraGenomicDataRequest was null or undefined when calling extractTanagraGenomicData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/datasets/extractTanagraGenomicData`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TanagraGenomicDataRequestToJSON(requestParameters.tanagraGenomicDataRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenomicExtractionJobFromJSON(jsonValue));
    }

    /**
     * Initiates a Terra Cromwell job to extract genomes of the given Tanagra-enabled dataset. Requires WRITER access on the workspace. Workspace CDR must also have an associated WGS dataset
     */
    async extractTanagraGenomicData(workspaceNamespace: string, terraName: string, tanagraGenomicDataRequest: TanagraGenomicDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenomicExtractionJob> {
        const response = await this.extractTanagraGenomicDataRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, tanagraGenomicDataRequest: tanagraGenomicDataRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDataDictionaryEntryRaw(requestParameters: DataSetApiGetDataDictionaryEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataDictionaryEntry>> {
        if (requestParameters.cdrVersionId === null || requestParameters.cdrVersionId === undefined) {
            throw new runtime.RequiredError('cdrVersionId','Required parameter requestParameters.cdrVersionId was null or undefined when calling getDataDictionaryEntry.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getDataDictionaryEntry.');
        }

        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value','Required parameter requestParameters.value was null or undefined when calling getDataDictionaryEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{cdrVersionId}/dataDictionaryEntry/{domain}/{value}`.replace(`{${"cdrVersionId"}}`, encodeURIComponent(String(requestParameters.cdrVersionId))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))).replace(`{${"value"}}`, encodeURIComponent(String(requestParameters.value))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataDictionaryEntryFromJSON(jsonValue));
    }

    /**
     */
    async getDataDictionaryEntry(cdrVersionId: number, domain: string, value: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataDictionaryEntry> {
        const response = await this.getDataDictionaryEntryRaw({ cdrVersionId: cdrVersionId, domain: domain, value: value }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDataSetRaw(requestParameters: DataSetApiGetDataSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getDataSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getDataSet.');
        }

        if (requestParameters.dataSetId === null || requestParameters.dataSetId === undefined) {
            throw new runtime.RequiredError('dataSetId','Required parameter requestParameters.dataSetId was null or undefined when calling getDataSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{workspaceNamespace}/{terraName}/data-sets/{dataSetId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"dataSetId"}}`, encodeURIComponent(String(requestParameters.dataSetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetFromJSON(jsonValue));
    }

    /**
     */
    async getDataSet(workspaceNamespace: string, terraName: string, dataSetId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSet> {
        const response = await this.getDataSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetId: dataSetId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDataSetByResourceIdRaw(requestParameters: DataSetApiGetDataSetByResourceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSetListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getDataSetByResourceId.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getDataSetByResourceId.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDataSetByResourceId.');
        }

        if (requestParameters.resourceTypeRequest === null || requestParameters.resourceTypeRequest === undefined) {
            throw new runtime.RequiredError('resourceTypeRequest','Required parameter requestParameters.resourceTypeRequest was null or undefined when calling getDataSetByResourceId.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{workspaceNamespace}/{terraName}/data-sets/dataSetByResourceId`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceTypeRequestToJSON(requestParameters.resourceTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDataSetByResourceId(workspaceNamespace: string, terraName: string, id: number, resourceTypeRequest: ResourceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSetListResponse> {
        const response = await this.getDataSetByResourceIdRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, id: id, resourceTypeRequest: resourceTypeRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve Job details for given ID. Requires READER access on the workspace.
     */
    async getGenomicExtractionJobsRaw(requestParameters: DataSetApiGetGenomicExtractionJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenomicExtractionJobListResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getGenomicExtractionJobs.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getGenomicExtractionJobs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/genomicExtractionJobs`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenomicExtractionJobListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve Job details for given ID. Requires READER access on the workspace.
     */
    async getGenomicExtractionJobs(workspaceNamespace: string, terraName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenomicExtractionJobListResponse> {
        const response = await this.getGenomicExtractionJobsRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all column names/values for a given domain.
     */
    async getValuesFromDomainRaw(requestParameters: DataSetApiGetValuesFromDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainValuesResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling getValuesFromDomain.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling getValuesFromDomain.');
        }

        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getValuesFromDomain.');
        }

        const queryParameters: any = {};

        if (requestParameters.conceptSetId !== undefined) {
            queryParameters['conceptSetId'] = requestParameters.conceptSetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/data-set/concept-sets/domainValues/{domain}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters.domain))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainValuesResponseFromJSON(jsonValue));
    }

    /**
     * Returns all column names/values for a given domain.
     */
    async getValuesFromDomain(workspaceNamespace: string, terraName: string, domain: string, conceptSetId?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainValuesResponse> {
        const response = await this.getValuesFromDomainRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, domain: domain, conceptSetId: conceptSetId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async markDirtyRaw(requestParameters: DataSetApiMarkDirtyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling markDirty.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling markDirty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{workspaceNamespace}/{terraName}/data-set/markDirty`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MarkDataSetRequestToJSON(requestParameters.markDataSetRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async markDirty(workspaceNamespace: string, terraName: string, markDataSetRequest?: MarkDataSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.markDirtyRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, markDataSetRequest: markDataSetRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Preview data set
     */
    async previewDataSetByDomainRaw(requestParameters: DataSetApiPreviewDataSetByDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSetPreviewResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling previewDataSetByDomain.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling previewDataSetByDomain.');
        }

        if (requestParameters.dataSetPreviewRequest === null || requestParameters.dataSetPreviewRequest === undefined) {
            throw new runtime.RequiredError('dataSetPreviewRequest','Required parameter requestParameters.dataSetPreviewRequest was null or undefined when calling previewDataSetByDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/workspaces/{workspaceNamespace}/{terraName}/data-set/preview`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSetPreviewRequestToJSON(requestParameters.dataSetPreviewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetPreviewResponseFromJSON(jsonValue));
    }

    /**
     * Preview data set
     */
    async previewDataSetByDomain(workspaceNamespace: string, terraName: string, dataSetPreviewRequest: DataSetPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSetPreviewResponse> {
        const response = await this.previewDataSetByDomainRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetPreviewRequest: dataSetPreviewRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Preview notebook code generation
     */
    async previewExportToNotebookRaw(requestParameters: DataSetApiPreviewExportToNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotebookResponse>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling previewExportToNotebook.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling previewExportToNotebook.');
        }

        if (requestParameters.dataSetExportRequest === null || requestParameters.dataSetExportRequest === undefined) {
            throw new runtime.RequiredError('dataSetExportRequest','Required parameter requestParameters.dataSetExportRequest was null or undefined when calling previewExportToNotebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{workspaceNamespace}/{terraName}/data-set/exportToNotebook/preview`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSetExportRequestToJSON(requestParameters.dataSetExportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotebookResponseFromJSON(jsonValue));
    }

    /**
     * Preview notebook code generation
     */
    async previewExportToNotebook(workspaceNamespace: string, terraName: string, dataSetExportRequest: DataSetExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotebookResponse> {
        const response = await this.previewExportToNotebookRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetExportRequest: dataSetExportRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDataSetRaw(requestParameters: DataSetApiUpdateDataSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSet>> {
        if (requestParameters.workspaceNamespace === null || requestParameters.workspaceNamespace === undefined) {
            throw new runtime.RequiredError('workspaceNamespace','Required parameter requestParameters.workspaceNamespace was null or undefined when calling updateDataSet.');
        }

        if (requestParameters.terraName === null || requestParameters.terraName === undefined) {
            throw new runtime.RequiredError('terraName','Required parameter requestParameters.terraName was null or undefined when calling updateDataSet.');
        }

        if (requestParameters.dataSetId === null || requestParameters.dataSetId === undefined) {
            throw new runtime.RequiredError('dataSetId','Required parameter requestParameters.dataSetId was null or undefined when calling updateDataSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/{workspaceNamespace}/{terraName}/data-sets/{dataSetId}`.replace(`{${"workspaceNamespace"}}`, encodeURIComponent(String(requestParameters.workspaceNamespace))).replace(`{${"terraName"}}`, encodeURIComponent(String(requestParameters.terraName))).replace(`{${"dataSetId"}}`, encodeURIComponent(String(requestParameters.dataSetId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DataSetRequestToJSON(requestParameters.dataSet),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetFromJSON(jsonValue));
    }

    /**
     */
    async updateDataSet(workspaceNamespace: string, terraName: string, dataSetId: number, dataSet?: DataSetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSet> {
        const response = await this.updateDataSetRaw({ workspaceNamespace: workspaceNamespace, terraName: terraName, dataSetId: dataSetId, dataSet: dataSet }, initOverrides);
        return await response.value();
    }

}
