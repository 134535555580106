/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TanagraGenomicDataRequest
 */
export interface TanagraGenomicDataRequest {
    /**
     * The ids of all cohorts in the data set
     * @type {Array<string>}
     * @memberof TanagraGenomicDataRequest
     */
    cohortIds: Array<string>;
    /**
     * The ids of all feature sets in the data set
     * @type {Array<string>}
     * @memberof TanagraGenomicDataRequest
     */
    featureSetIds: Array<string>;
    /**
     * Id of the new dataset to attach to the extraction job
     * @type {number}
     * @memberof TanagraGenomicDataRequest
     */
    datasetId: number;
    /**
     * 
     * @type {boolean}
     * @memberof TanagraGenomicDataRequest
     */
    allParticipants?: boolean;
}

/**
 * Check if a given object implements the TanagraGenomicDataRequest interface.
 */
export function instanceOfTanagraGenomicDataRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cohortIds" in value;
    isInstance = isInstance && "featureSetIds" in value;
    isInstance = isInstance && "datasetId" in value;

    return isInstance;
}

export function TanagraGenomicDataRequestFromJSON(json: any): TanagraGenomicDataRequest {
    return TanagraGenomicDataRequestFromJSONTyped(json, false);
}

export function TanagraGenomicDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TanagraGenomicDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cohortIds': json['cohortIds'],
        'featureSetIds': json['featureSetIds'],
        'datasetId': json['datasetId'],
        'allParticipants': !exists(json, 'allParticipants') ? undefined : json['allParticipants'],
    };
}

export function TanagraGenomicDataRequestToJSON(value?: TanagraGenomicDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cohortIds': value.cohortIds,
        'featureSetIds': value.featureSetIds,
        'datasetId': value.datasetId,
        'allParticipants': value.allParticipants,
    };
}

